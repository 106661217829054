
.ant-layout-sider-trigger {
  background-color: #ffffff !important;
  color: black !important;
  box-shadow: inset 0px 1px 0px #f0f0f0;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 17px !important;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-light.ant-menu-root.ant-menu-vertical,
:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-vertical
  > .ant-menu.ant-menu-root.ant-menu-vertical {
  box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-input-affix-wrapper {
  /* border-radius: 0px;  */
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-divider-horizontal {
  margin-top: 12px !important;
}
.ant-menu-item.ant-menu-item-only-child {
  padding-inline: 0px;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-input-search
  .ant-input-search-button {
  display: flex;
  align-items: center;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-select-single
  .ant-select-selector {
  /* border-radius: 0px; */
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-card .ant-card-body {
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 0px;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-card {
  /* border-radius: 0px; */
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-card .ant-card-cover img {
  /* border-radius: 0px; */
}

.ant-table {
  overflow: auto;
}

:where(.css-1e3x2xa).ant-btn-primary {
  background-color: #1677ff !important;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-disabled {
  cursor: pointer;
  color: black;
}

.ant-picker-dropdown
  .ant-picker-panel-container {
  background: linear-gradient(
      0deg,
      rgba(148, 193, 31, 0.05),
      rgba(148, 193, 31, 0.05)
    ),
    #fffbfe;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #8dc63f !important;
}

.ant-picker-dropdown
  .ant-picker-today-btn {
  color: #8dc63f !important;
  font-weight: 600;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-radius: 100px;
  border-color: #8dc63f !important;
  border-width: 0.1rem;
  padding: 5px;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #8dc63f !important;
  border-radius: 50% !important;
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: #8dc63f !important;
  color: white;
}

.ant-picker-dropdown
  .ant-picker-header-view
  button:hover {
  color: #8dc63f !important;
}

.ant-picker-dropdown
  .ant-picker-header
  > button:hover {
  color: #8dc63f !important;
}

.ant-picker-dropdown .ant-picker-today-btn:hover {
  color: #8dc63f !important;
}


.ant-picker .ant-picker-input>input:placeholder-shown {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
}


:where(.css-dev-only-do-not-override-1hpnbz2).ant-list .ant-list-item {
  padding: 2px;
}